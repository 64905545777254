import { useAuthUserSelector, useDialogWithItem } from 'hooks';
import { StationEntity, StationLocation, StationType, BaseStation } from 'models/base-station';
import { apiLorawanRawMessagesPath, apiRawMessagesPath, getBaseStationDetailPath } from 'routing/paths';
import { canEditStation, isAdmin } from 'utils/permissions';
import { EnumRawMessagesFields } from 'models/raw-messages';
import { formatLocationTitle } from '../widgets/utils';
import { commonOptions } from 'utils/tables';
import { usePagination } from 'hooks/usePagination';

// components
import { Box, Typography } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
import { BaseStationLink, ProjectLink } from 'components/Links';
import { HealthyStatus, OnlineStatus } from '../widgets';
import { BaseStationFrequency, BaseStationGeneration, OwnerName } from 'components/Labels';
import { CommonButton, PlusButton } from 'components/Buttons';
import { AddLocationDialog, EditLocationDialog } from '../widgets/Dialogs';

// styles
import { getMuiTheme } from 'styles/themes';
import { ThemeProvider } from 'styles/utils';

interface Props {
  isLoading?: boolean;
  data: StationEntity[];
}

export const StationTable = (props: Props): JSX.Element => {
  const { isLoading, data } = props;
  const user = useAuthUserSelector();
  const { page, limit } = usePagination();
  const addLocationDialog = useDialogWithItem<BaseStation>();
  const editLocationDialog = useDialogWithItem<StationLocation>();

  const columns: MUIDataTableColumn[] = [
    {
      name: 'stationId',
      label: 'Station ID',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          const station = data[dataIndex];
          if (station.type === StationType.flash) {
            return <Typography variant="inherit">{ station.id }</Typography>;
          }
          if (station.type === StationType.location) {
            return <Typography variant="inherit"> - </Typography>;
          }
          if(station.type === StationType.lorawan) {
            return <BaseStationLink stationId={ station.id } label={ `${ station.eui }` }/>;
          }
          if (station.type === StationType.station) {
            return <BaseStationLink stationId={ station.id } label={ `${ station.id }` }/>;
          }
        },
      },
    },
    {
      name: 'isOnline',
      label: 'Online status',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex => {
          const station = data[dataIndex];
          if (station.type === StationType.location) {
            return '-';
          }
          if (station.type === StationType.station || station.type === StationType.lorawan) {
            return <OnlineStatus stationStatus={ station.status }/>;
          }
          return <OnlineStatus />;
        }
      }
    },
    {
      name: 'isHealthy',
      label: 'Health status',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex =>  {
          const station = data[dataIndex];
          if (station.type === StationType.location || station.type === StationType.lorawan) {
            return '-';
          }
          if (station.type === StationType.station) {
            return <HealthyStatus stationStatus={ station.status }/>;
          }

          return <HealthyStatus />;
        }
      },
    },
    {
      name: 'location',
      label: 'Location',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          const station = data[dataIndex];
          if (station.type === StationType.flash) {
            return 'No location';
          }

          if (station.type === StationType.location) {
            return <span data-haslocation>{ formatLocationTitle(station.location) }</span>;
          }

          if (station.location) {
            return <span data-haslocation>{ formatLocationTitle(station.location) }</span>;
          }

          return !canEditStation(user) ? 'No location' :
            <Box ml="-14px">
              <PlusButton
                type="icon"
                label="Set location"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // @TODO BNIV-2216 fix types
                onClick={ () => addLocationDialog.open(station) }
              />
              Set location
            </Box>;
        }
      }
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          const station = data[dataIndex];
          if (!station.location || !station.location.project) {
            return '—';
          }

          return <ProjectLink projectId={ station.location.project }/>;
        }
      }
    },
    {
      name: 'frequency',
      label: 'Frequency (MHz)',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          const station = data[dataIndex];
          if (station.type === StationType.location) {
            return '-';
          }

          if (station.type !== StationType.lorawan && !station.frequency) {
            return 'Not set';
          }

          if(station.type === StationType.lorawan) {
            return station.rf_region;
          }

          if(station.frequency) {
            return <BaseStationFrequency stationFrequency={ station.frequency }/>;
          }
        },
      },
    },
    {
      name: 'generation',
      label: 'Generation',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          const station = data[dataIndex];
          if (station.type === StationType.location || station.type === StationType.lorawan) {
            return '-';
          }
          if (station.type === StationType.station || station.type === StationType.flash) {
            return <BaseStationGeneration stationGeneration={ station.generation }/>;
          }
        },
      }
    },
    {
      name: 'owner',
      label: 'Owner ID',
      options: {
        filter: false,
        sort: false,
        display: isAdmin(user) ? 'true' : 'excluded',
        customBodyRenderLite: dataIndex => <OwnerName ownerId={ data[dataIndex].owner_id }/>
      }
    },
    {
      name: 'action',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          const station = data[dataIndex];
          if (station.type === StationType.flash) {
            return '';
          }

          if (station.type === StationType.location) {
            return (
              <Box display="flex" justifyContent="flex-end">
                <CommonButton
                  type="text"
                  label="Edit"
                  icon={ <></> }
                  onClick={ () => editLocationDialog.open(station.location) }
                />
              </Box>
            );
          }

          if(station.type === StationType.lorawan) {
            return (
              <Box display="flex" justifyContent="flex-end">
                <CommonButton
                  type="text"
                  label="Messages"
                  icon={ <></> }
                  LinkTo={ apiLorawanRawMessagesPath({
                    gatewayEUI: station.eui
                  }) }
                />
                <CommonButton
                  type="text"
                  label="Edit"
                  icon={ <></> }
                  LinkTo={ getBaseStationDetailPath(station.id) }
                />
              </Box>
            );
          }
          if (station.type === StationType.station) {
            return (
              <Box display="flex" justifyContent="flex-end">
                <CommonButton
                  type="text"
                  label="Messages"
                  icon={ <></> }
                  LinkTo={ apiRawMessagesPath({
                    [EnumRawMessagesFields.bsid]: station.id
                  }) }
                />
                <CommonButton
                  type="text"
                  label="Edit"
                  icon={ <></> }
                  LinkTo={ getBaseStationDetailPath(station.id) }
                />
              </Box>
            );
          }
        },
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    page,
    rowsPerPage: limit,
    customFooter: (count, _page, _rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <PaginationFooter
          count={ count }
          onChangeRowsPerPage={ changeRowsPerPage }
          onChangePage={ changePage }
        />
      );
    },
    elevation: 0,
  };

  return (
    <>
      <ThemeProvider theme={ getMuiTheme() }>
        <TableLoadingLayout isLoading={ isLoading }>
          <MUIDataTable
            title={ null }
            data={ data }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </ThemeProvider>
      { addLocationDialog.isMounted && addLocationDialog.item && <AddLocationDialog
        station={ addLocationDialog.item }
        isOpen={ addLocationDialog.isOpen }
        onClose={ addLocationDialog.close }
        onCloseEnd={ addLocationDialog.unmount }
      /> }
      { editLocationDialog.isMounted && editLocationDialog.item && <EditLocationDialog
        location={ editLocationDialog.item }
        isOpen={ editLocationDialog.isOpen }
        onClose={ editLocationDialog.close }
        onCloseEnd={ editLocationDialog.unmount }
      /> }
    </>
  );
};
