import { StationFrequency } from './StationFrequency';
import { StationGeneration } from './StationGeneration';
import { StationReceiverType } from './StationReceiverType';
import { StationLocation } from './StationLocation';
import { StationFlash } from './StationFlash';
import { StationStatusFlat } from 'models/base-station-status';

export enum StationType {
  station = 'station',
  flash = 'flash',
  location = 'location',
  lorawan = 'lorawan',
}

export interface Station<T = StationType.station> {
  id: number;
  owner_id: number;
  generation: StationGeneration;
  receiver_type: StationReceiverType;
  frequency?: StationFrequency;
  location?: StationLocation;
  status?: StationStatusFlat;
  type: T
};

export interface StationWithStatus extends Station {
  status?: StationStatusFlat;
}

export type BaseStation = Station & { status?: StationStatusFlat } // base station
export type FlashStation = Station<StationType.flash> & { flash: StationFlash } // station with "software update" status. can't attach / detach location
export type LocationStation = Partial<Station<StationType.location>> & { location: StationLocation } // location (without station)
export type LorawanStation = Pick<Station, 'id' | 'owner_id' | 'location' | 'status'> & { // lorawan-type stations
  type: StationType.lorawan,
  name: string;
  aws_id: string;
  eui: string;
  rf_region: string;
  lastMsg?: string;
}

export type StationEntity = BaseStation | FlashStation | LocationStation | LorawanStation;

export interface GetStationParams {
  stationIds?: number[],
  ownerId?: number,
  city?: string,
  country?: string,
  offset?: number,
  limit?: number,
}

// send null if you want remove field value
export interface StationUpdate {
  ownerId: number,
  generation: StationGeneration,
  receiverType: StationReceiverType,
  frequency?: StationFrequency | null,
  locationId?: number | null,
}

export interface LoRaWANStationUpdate {
  owner_id: number;
  locationId?: number | null;
  aws_id?: string;
  eui: string;
  rf_region: string;
  name: string;
}

export interface StationCreate {
  id: number,
  ownerId: number,
  generation: StationGeneration,
  receiverType: StationReceiverType,
  frequency?: StationFrequency,
}

export const LoRaWANRFRegions = [
  'EU868',
  'US915',
  'CN779',
  'EU433',
  'AU915',
  'CN470',
  'AS923-1',
  'AS923-2',
  'AS923-3',
  'AS923-4',
  'KR920',
  'IN865',
  'RU864',
  '',
] as const;

export type LoRaWANRFRegion = typeof LoRaWANRFRegions[number];

export interface StationLoRaWANCreate {
  eui: string
  owner_id: number
  rf_region: LoRaWANRFRegion
}
