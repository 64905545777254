import React from 'react';
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';
import { LoRaWANRFRegion, LoRaWANRFRegions } from 'models/base-station';


export type SinglePropsRF = SingleProps<LoRaWANRFRegion>;
export type MultiPropsRF = MultiProps<LoRaWANRFRegion>;

type Props =
  Partial<Omit<CommonProps<LoRaWANRFRegion | ''>, 'options'>>
  & (SinglePropsRF | MultiPropsRF)
  & { filter?: (region: LoRaWANRFRegion) => boolean };

export const RFRegionSelectControl = (props: Props): JSX.Element => {
  const { filter = () => true } = props;

  const options: Option<LoRaWANRFRegion>[] = LoRaWANRFRegions
    .filter(filter)
    .map((region) => ({
      value: region,
      label: region,
    }));

  return (
    <SelectControl
      name="rf_region"
      label="RF Region"
      {...props}
      options={options}
      checkSelected={LoRaWANRFRegions.length > 0}
      isDisabled={props.isDisabled || !options.length}
    />
  );
};