import MUIDataTable from 'mui-datatables';
import { UserGroup } from 'models/user-management';
import { canInviteUser } from 'utils/permissions';
import { useDialog } from 'hooks';
import { useAuthUserSelector } from 'hooks/user';
import { useUsersSelector } from 'hooks/user-managment';
import { commonOptions } from 'utils/tables';
import { useInternalPaginator } from 'utils/paginator';

// components
import { CardHeader, CardContent, Card, Button, Box } from '@mui/material';
import { TableLoadingLayout } from 'components/Table';
import { UserLink } from 'components/Links';
import { UserPermissions, UserProjects, UserNotConfirmed, UserInviteDialog } from 'components/UserManagement';

// styles
import { ThemeProvider } from 'styles/utils';
import { getTableTheme } from 'styles/themes';

interface Props {
  userGroup: UserGroup;
}

export const UsersBlock = ({ userGroup }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const inviteDialog = useDialog();
  const { isLoading, users } = useUsersSelector({
    owner: userGroup.id,
    limit: 999,
    offset: 0
  });

  const { data, ...options } = useInternalPaginator(users, 10, [10, 20, 30]);

  return (
    <>
      <Card sx={ { height: '100%' } }>
        <CardHeader
          title="Users"
          action={
            canInviteUser(user) && (
              <Button onClick={ inviteDialog.open }>Invite</Button>
            )
          }
        />
        <CardContent
          sx={ {
            paddingTop: 0,
            paddingBottom: '0px!important',
          } }
        >
          <TableLoadingLayout isLoading={ isLoading }>
            <ThemeProvider theme={ getTableTheme() }>
              <MUIDataTable
                title=""
                data={ data }
                columns={ [
                  {
                    name: 'name',
                    label: 'Name',
                    options: {
                      filter: false,
                      sort: false,
                      setCellHeaderProps: () => ({ style: { width: '30%' } }),
                      customBodyRenderLite: index => {
                        const user = data[index];
                        return (
                          <Box sx={ { display: 'flex', alignItems: 'center' } }>
                            <UserLink
                              sub={ user.sub }
                              firstName={ user.first_name }
                              lastName={ user.last_name }
                            />
                            <UserNotConfirmed user={ user }/>
                          </Box>
                        );
                      }
                    }
                  },
                  {
                    name: 'email',
                    label: 'Email',
                    options: {
                      filter: false,
                      sort: false,
                      setCellHeaderProps: () => ({ style: { width: '30%' } }),
                      customBodyRenderLite: index => {
                        const user = data[index];
                        return (
                          <UserLink
                            sub={ user.sub }
                            firstName={ user.email }
                            lastName=""
                          />
                        );
                      }
                    }
                  },
                  {
                    name: 'permissions',
                    label: 'Permission',
                    options: {
                      filter: false,
                      sort: false,
                      customBodyRenderLite: index => <UserPermissions user={ data[index] }/>,
                    },
                  },
                  {
                    name: 'projects',
                    label: 'Projects',
                    options: {
                      filter: false,
                      sort: false,
                      setCellHeaderProps: () => ({ style: { width: '20%' } }),
                      customBodyRenderLite: index => <UserProjects user={ data[index] }/>,
                    },
                  },
                ] }
                options={ {
                  ...commonOptions,
                  ...options,
                  responsive: 'simple',
                  elevation: 0,
                } }
              />
            </ThemeProvider>
          </TableLoadingLayout>
        </CardContent>
      </Card>
      { inviteDialog.isMounted && (
        <UserInviteDialog
          user={ { owner: userGroup.id } }
          isOpen={ inviteDialog.isOpen }
          onClose={ inviteDialog.close }
          onCloseEnd={ inviteDialog.unmount }
        />
      ) }
    </>
  );
};
