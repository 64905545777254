const baseUrl = '/network';
const stationParam = '{stationId}';

enum Path {
  list = '/stations',
  create = '/stations/create',
  detail = '/stations/{stationId}',
  coverageMap = '/bs-coverage-map',
}

export function getBaseStationListPath(): string {
  return baseUrl + Path.list;
}

export function getBaseStationCreatePath(): string {
  return baseUrl + Path.create;
}

export function getBaseStationDetailPath(stationId: string | number) {
  return baseUrl + Path.detail.replace(stationParam, String(stationId));
}

export function getLoRaWANBaseStationDetailPathWithCertificates(stationId: string | number) {
  return baseUrl + Path.detail.replace(stationParam, String(stationId)) + '?downloadCertificates=1';
}

export function getBaseStationCoverageMapPath(): string {
  return baseUrl + Path.coverageMap;
}