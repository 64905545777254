import { useMutation } from 'react-query';
import { createUser } from 'clients/user-management';
import { PROJECT_ALL, UserCreateParams } from 'models/user-management';
import { useFormActionNotifier } from 'hooks/form';
import { clearQueryCache } from 'utils/react-query';
import { getResponse } from 'utils/clients';

export const useUserCreateMutation = () => {
  const notifier = useFormActionNotifier();

  const mutation = useMutation({
    mutationFn: async (params: UserCreateParams) => {
      const res = await createUser({
        ...params,
        projects: params.projects.includes(PROJECT_ALL) ? 'all' : params.projects
      });

      return getResponse(res);
    },
    onError: (e) => {
      let message = (e as Error).message;
      if (message.startsWith('Found 1 users with email')) {
        message = 'User already exists';
      }

      notifier.notifyError(`Error while creating a user: ${ message }`);
    },
    onSuccess: async () => {
      await clearQueryCache('userManagement/user');
      notifier.notifySuccess('User create successfully');
    },
  });

  return {
    isLoading: mutation.isLoading,
    createUser: mutation.mutateAsync
  };
};
