import { History } from 'models/caller';
import { apiRawMessagesPath, apiLorawanRawMessagesPath } from 'routing/paths';
import { useDevices } from 'hooks/device-management';

// components
import { Typography, Paper, Grid, TableRow, TableCell, Box, Link } from '@mui/material';
import { CustomLink } from 'components/Links';
import { JsonPreview } from 'components/JsonPreview/JsonPreview';

// styles
import useStyles from './Style';

interface AdditionalInfoProps {
  rows: number;
  data: History;
}

const AdditionalInfo = ({ rows, data }: AdditionalInfoProps) => {
  const classes = useStyles();

  const device_id = String(data.device_id);
  const { devices } = useDevices({ params: {
    devices: [device_id],
    zones: [],
    groups: [],
  } });
  const { protocol_name } = devices[0] || {};
  const rawMessageslink = protocol_name === 'unb' ?
    apiRawMessagesPath({ traceid: data.msg_trace_id }) :
    apiLorawanRawMessagesPath({ traceid: data.msg_trace_id });


  const headers: Record<string, string> = data.req_headers ? data.req_headers as unknown as Record<string, string> : {};

  return (
    <TableRow>
      <TableCell colSpan={ rows }>
        <Paper>
          <Box p={ 2 }>
            <Grid container spacing={ 0 }>
              <Grid item xs={ 12 } md={ 6 }>
                <Typography variant="subtitle2" className={ classes.value }>
                  Reception Time &nbsp;&nbsp; <Typography component="span">{ data.got_time }</Typography>
                </Typography>
                <Typography variant="subtitle2" className={ classes.value }>
                  Trace id &nbsp;&nbsp; <Link
                    className={ classes.link }
                    component={ CustomLink }
                    color="secondary"
                    to={ rawMessageslink }
                  >{ data.msg_trace_id }</Link>
                </Typography>
                <Typography variant="subtitle2" className={ classes.value }>
                  Attempt number &nbsp;&nbsp; <Typography component="span">{ data.attempt_number }</Typography>
                </Typography>
                <Typography variant="subtitle2" className={ classes.value }>
                  Method &nbsp;&nbsp; <Typography component="span">{ data.req_method }</Typography>
                </Typography>
                <Typography variant="subtitle2" className={ classes.value }>
                  URL &nbsp;&nbsp; <Typography component="span">{ data.req_url }</Typography>
                </Typography>

                {
                  Object.entries(headers).length ?
                    <Box mt={ 1 }>
                      <Grid container spacing={ 0 }>
                        <Grid item md={ 4 }><Typography variant="subtitle2">Name</Typography></Grid>
                        <Grid item md={ 8 }><Typography variant="subtitle2">Value</Typography></Grid>
                      </Grid>

                      {
                        Object.entries(headers).map(([key, value]) => (
                          <Grid container spacing={ 0 } key={ key }>
                            <Grid item md={ 4 }>
                              <Box borderBottom={ 1 } py={ 1 } mr={ 2 } height="100%">
                                <Typography className={ classes.breakAll }>{ key }</Typography>
                              </Box>
                            </Grid>
                            <Grid item md={ 8 }>
                              <Box borderBottom={ 1 } py={ 1 } mr={ 2 } height="100%">
                                <Typography className={ classes.breakAll }>{ value }</Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        ))
                      }
                    </Box>
                    : ''
                }
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                { data.req_body
                  ? <JsonPreview title="Body" valueJson={ data.req_body } />
                  : <Typography variant="subtitle2">Empty Body</Typography>
                }
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </TableCell>
    </TableRow>
  );
};

export default AdditionalInfo;
