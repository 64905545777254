import { useUserGroupsServices } from 'hooks/user-managment';
import { UserGroupServiceName } from 'models/user-management';

// components
import { CardHeader, CardContent, Card } from '@mui/material';
import { PageLoader } from 'components/Loaders';
import { SwitchControl } from 'components/Controls';

interface Props {
  userGroupId: number;
}

export const ServicesBlock = ({ userGroupId }: Props): JSX.Element => {
  const { isLoading, services, onActivateService, onDeactiveateService } = useUserGroupsServices(userGroupId);

  return (
    <Card sx={ { height: '100%' } }>
      <CardHeader title="Services"/>
      <CardContent>
        { isLoading && <PageLoader/> }
        <SwitchControl
          name="parkingAnalytics"
          label="Parking Analytics"
          value={ services.find(service => service.name === 'analytics')?.active ?? false }
          onChange={ active => {
            active ? onActivateService(UserGroupServiceName.ANALYTICS) : onDeactiveateService(UserGroupServiceName.ANALYTICS);
          } }
        />
      </CardContent>
    </Card>
  );
};
