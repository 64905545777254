import { State as UserState } from 'reducers/user-profile';

export function canViewStationList(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['stations']?.includes('r'));
}

export function canViewStation(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['stations']?.includes('r'));
}

export function canCreateStation(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['stations']?.includes('c'));
}

export function canEditStation(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['stations']?.includes('u'));
}

export function canEditLorawanStation(user: UserState): boolean {
  return user.isAdmin;
}

export function canRemoveLorawanStation(user: UserState): boolean {
  return user.isAdmin;
}

export function canDeleteStation(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['stations']?.includes('d'));
}

