import { useState } from 'react';
import { BaseStation, LorawanStation, StationType } from 'models/base-station';
import { useDialog, useAuthUserSelector } from 'hooks';

import greenMarker from 'images/Map/MarkerRadioGreen.png';
import redMarker from 'images/Map/MarkerRadioRed.png';
import { canEditStation } from 'utils/permissions';

// components
import { InfoWindow, Marker } from '@react-google-maps/api';
import { Box, Typography } from '@mui/material';
import { CommonMap } from 'components/Map';
import { StationBlock } from './StationBlock';
import { AddLocationDialog, EditLocationDialog } from '../../../widgets/Dialogs';
import { formatLocationTitle } from '../../../widgets/utils'; // @TODO
import { ProjectLink } from 'components/Links';
import { EditButton, PlusButton } from 'components/Buttons';
// styles
import useStyles from './style';

interface Props {
  readonly station: BaseStation | LorawanStation;
}

export const LocationBlock = ({ station }: Props): JSX.Element => {
  const classes = useStyles();
  const attachDialog = useDialog();
  const editDialog = useDialog();
  const user = useAuthUserSelector();
  const isOnline = station.status && !station.status.isOffline;
  const [isOpen, setOpen] = useState<boolean>(true);

  if (!station.location) {
    return (
      <Box className={ classes.stationLocationEmptyRoot }>
        <Box className={ classes.stationManagementHeader }>
          <Typography className={ classes.headerLocationTitle }>No location</Typography>
          { canEditStation(user) && (
            <>
              <PlusButton type="icon" onClick={ attachDialog.open } label="Set location"/>
              { attachDialog.isMounted && <AddLocationDialog
                station={ station }
                isOpen={ attachDialog.isOpen }
                onClose={ attachDialog.close }
                onCloseEnd={ attachDialog.unmount }
              /> }
            </>
          ) }
        </Box>
      </Box>
    );
  }

  return (
    <Box className={ classes.stationLocationRoot }>
      <Box className={ classes.stationManagementHeader }>
        <Typography className={ classes.headerLocationTitle }>
          Project: <ProjectLink projectId={ station.location.project } /><br/>
          { formatLocationTitle(station.location) }
        </Typography>
        { canEditStation(user) && (
          <>
            <EditButton type="icon" onClick={ editDialog.open }/>
            { editDialog.isMounted && <EditLocationDialog
              location={ station.location }
              isOpen={ editDialog.isOpen }
              onClose={ editDialog.close }
              onCloseEnd={ editDialog.unmount }
            /> }
          </>
        ) }
      </Box>
      <Box className={ classes.blockLocationContentRoot }>
        <CommonMap
          zoom={ 16 }
          defaultCenter={ {
            lat: station.location.lat || 0,
            lng: station.location.lon || 0,
          } }
        >
          <Marker
            key={ station.location.id }
            icon={ isOnline ? greenMarker : redMarker }
            onClick={ () => setOpen(true) }
            position={ {
              lat: station.location.lat || 0,
              lng: station.location.lon || 0,
            } }
          >
            { isOpen && (
              <InfoWindow
                onCloseClick={ () => setOpen(false) }
                position={ {
                  lat: station.location.lat || 0,
                  lng: station.location.lon || 0,
                } }
              >
                <Box width="100%" display="flex" alignItems="center" flexDirection="column">
                  <Typography variant="subtitle1">
                    { `Bound to  ${ station.type === StationType.lorawan ? station.eui : station.id }` }
                  </Typography>
                </Box>
              </InfoWindow>
            ) }
          </Marker>
        </CommonMap>
      </Box>
      <StationBlock station={ station }/>
    </Box>
  );
};
