import { useQuery } from 'react-query';
import { fetchPermissions } from 'clients/user-management';
import { Permissions } from 'models/user-management';
import { useFormActionNotifier } from 'hooks/form';
import { ensureRequestSucceeded } from 'utils/clients';

interface PermissionState {
  isLoading: boolean;
  permission?: Permissions;
}

export function usePermissionSelector(permissionId: number): PermissionState {
  const { isLoading, permissions } = usePermissionsDictionary();
  return {
    isLoading: isLoading,
    permission: permissions.find(p => p.id === permissionId),
  };
}

export interface PermissionsState {
  isLoading: boolean;
  permissions: Permissions[];
}

export function usePermissionsDictionary(enabled = true): PermissionsState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['userManagement/permission'],
    queryFn: async () => {
      const result = await fetchPermissions();
      ensureRequestSucceeded(result);

      return result.data ?? [];
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    onError: e => notifyError(`Error while fetching permissions: ${ (e as Error).message }`),
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    permissions: queryResult.data ?? [],
  };
}
