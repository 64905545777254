/**
 * Dictionary hooks for get common data from backend without filters and specific logic
 *
 * All hooks must return a typed list of objects
 * Hooks have simple logic for loading data into the directory state and provide for checking data reloading
 * both at the component level and the state level.
 * Logic steps:
 * 1) Check, what component load dictionary once ( implement  by useEffect )
 * 2) Check, what state load dictionary once ( implement by isInited flag in state )
 * 3) Send action to load dictionary from backend ( implement by dispatch action )
 *
 * Reason for useEffect:
 * 1) it hooks like componentDidMount
 * 2) Dictionary specified for user and need to refresh after login, after drop state isInit flag trigered useEfect and rerender component
 * 3) Some components have a dependency on dispatch and rerender in cyclic, useEffect allows dispatch load action once
 */
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { DictionaryLoad } from 'actions/dictionary';
import { Endpoint, MessageType } from 'models/rabbit';
import { TemplateSuite } from 'models/caller';
import { Dictionary } from 'models/dictionary';

type DataSelector<T> = (state: RootState) => T[];

function useDictionary<T>(dictionary: Dictionary, selector: DataSelector<T>): T[] {
  const dispatch = useDispatch();
  const isInited = useSelector((state: RootState) => state.dictionary.inited.has(dictionary));
  const data = useSelector(selector, shallowEqual);
  useEffect(() => {
    if (!isInited) {
      dispatch(DictionaryLoad(dictionary));
    }
  }, [dispatch, isInited, dictionary]);

  return data;
}

export const useHardwareTypesDictionary = (): string[] => {
  return useDictionary<string>(
    Dictionary.DM_HARDWARE_TYPES,
    state => state.dictionary.hardwareTypes
  );
};

export const useEndpointsDictionary = (): Endpoint[] => {
  return useDictionary<Endpoint>(
    Dictionary.RABBIT_ENDPOINTS,
    state => state.dictionary.endpoints
  );
};

export const useMessageTypesDictionary = (): MessageType[] => {
  return useDictionary<MessageType>(
    Dictionary.RABBIT_MESSAGE_TYPES,
    state => state.dictionary.messageTypes
  );
};

export const useTemplateSuitesDictionary = (): TemplateSuite[] => {
  return useDictionary<TemplateSuite>(
    Dictionary.CALLER_TEMPLATE_SUITES,
    state => state.caller.templateSuite.findByParams({})
  );
};
