import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';

import { NotifyError, NotifySuccess } from 'actions/notifier';
import * as umPermissionsActions from 'actions/user-management/permissions';
import * as umUsersActions from 'actions/user-management/users';
import * as umClient from 'clients/user-management';
import { ApiResponse, ApiResponseWithTotal, ReasonEnum } from 'models';
import { Permissions, User, UserUpdateParams } from 'models/user-management';
import { ActionWithPromise } from 'utils/store';
import { clearQueryCache } from 'utils/react-query';
import { createErrorFromApiResponse } from 'utils/errors';
import { Auth } from 'aws-amplify';

type CreatePermissions = umPermissionsActions.CreatePermissions;
type CreatePermissionsResult = umPermissionsActions.CreatePermissionsResult;
type CreatePermissionsSagaAction = (
  | CreatePermissions
  | ActionWithPromise<CreatePermissions, CreatePermissionsResult>
);

function* createPermissionsSaga(action: CreatePermissionsSagaAction) {
  const response: ApiResponse = yield call(umClient.createPermissions, action.payload);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';

    yield put(NotifyError(`Error while creating permissions: ${ message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(message));
    }

    return;
  }

  const permissions: Permissions = response.data;

  yield put(umPermissionsActions.createPermissionsSuccess(permissions));
  yield clearQueryCache('userManagement/permission');

  if ('meta' in action) {
    action.meta.promise.resolve(permissions);
  }
}

type DeleteUser = umUsersActions.DeleteUser;
type DeleteUserResult = umUsersActions.DeleteUserResult;
type DeleteUserSagaAction = (
  | DeleteUser
  | ActionWithPromise<DeleteUser, DeleteUserResult>
);

function* deleteUserSaga(action: DeleteUserSagaAction) {
  const response: ApiResponse = yield call(umClient.deleteUser, action.userSub);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';

    yield put(NotifyError(`Error while deleting a user: ${ message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(message));
    }

    return;
  }

  yield put(umUsersActions.deleteUserSuccess(action.userSub));
  yield clearQueryCache('userManagement');

  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

type DeletePermissions = umPermissionsActions.DeletePermissions;
type DeletePermissionsResult = umPermissionsActions.DeletePermissionsResult;
type DeletePermissionsSagaAction = (
  | DeletePermissions
  | ActionWithPromise<DeletePermissions, DeletePermissionsResult>
);

function* deletePermissionsSaga(action: DeletePermissionsSagaAction) {
  const response: ApiResponse = yield call(umClient.deletePermissions, action.id);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';

    yield put(NotifyError(`Error while deleting permissions: ${ message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(message));
    }

    return;
  }

  yield put(umPermissionsActions.deletePermissionsSuccess(action.id));
  yield clearQueryCache('userManagement/permission');

  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

type UpdatePermissions = umPermissionsActions.UpdatePermissions;
type UpdatePermissionsResult = umPermissionsActions.UpdatePermissionsResult;
type UpdatePermissionsSagaAction = (
  | UpdatePermissions
  | ActionWithPromise<UpdatePermissions, UpdatePermissionsResult>
);

function* updatePermissionsSaga(action: UpdatePermissionsSagaAction) {
  const response: ApiResponse = yield call(umClient.updatePermissions, action.payload);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';

    yield put(NotifyError(`Error while updating permissions: ${ message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(message));
    }

    return;
  }

  const permissions: Permissions = response.data;

  yield put(umPermissionsActions.updatePermissionsSuccess(permissions));
  yield clearQueryCache('userManagement/permission');

  if ('meta' in action) {
    action.meta.promise.resolve(permissions);
  }
}

function* fetchPermissionsSaga(_action: umPermissionsActions.FetchPermissions) {
  const response: ApiResponse = yield call(umClient.fetchPermissions);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';
    yield put(umPermissionsActions.fetchPermissionsFailure(message));
    yield put(NotifyError(`Error while fetching permissions: ${ message }`));
    return;
  }

  const permissions: Permissions[] = response.data;
  const permissionsById = Object.fromEntries(permissions.map(p => [p.id, p]));

  yield put(umPermissionsActions.fetchPermissionsSuccess({ permissionsById }));
}

function* fetchUserSaga(action: umUsersActions.FetchUser) {
  const response: ApiResponse = yield call(umClient.fetchUser, action.userSub);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';
    yield put(umUsersActions.fetchUserFailure(message));
    yield put(NotifyError(`Error while fetching a user: ${ message }`));
    return;
  }

  let user: User | null;

  // API responds with `data: {}` for nonexistent users for now
  if (!response.data || !Object.keys(response.data).length) {
    user = null;
  } else {
    user = response.data;
  }

  yield put(umUsersActions.fetchUserSuccess({ user, userSub: action.userSub }));
}

type UpdateUser = umUsersActions.UpdateUser;
type UpdateUserResult = umUsersActions.UpdateUserResult;
type UpdateUserSagaAction = (
  | UpdateUser
  | ActionWithPromise<UpdateUser, UpdateUserResult>
);

function* updateUserSaga(action: UpdateUserSagaAction) {
  const response: ApiResponse = yield call(umClient.updateUser, action.payload);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';

    yield put(NotifyError(`Error while updating a user: ${ message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(message));
    }

    return;
  }

  const user: User = response.data;

  yield put(umUsersActions.updateUserSuccess({ user, sub: action.payload.sub }));
  yield clearQueryCache('userManagement');

  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

type AddUsersToProjectAction =
  | umUsersActions.AddUsersToProject
  | ActionWithPromise<umUsersActions.AddUsersToProject, User[]>

function* doAddUsersToProject(action: AddUsersToProjectAction) {
  const { projectId } = action;
  const updated: User[] = [];
  try {
    for (const user of action.users) {
      const update: UserUpdateParams = {
        sub: user.sub,
        props: { ...user, projects: [...user.projects, projectId] as number[] }
      };
      const response: ApiResponse<User> = yield call(umClient.updateUser, update);
      if (response.reason === ReasonEnum.Ok && response.data) {
        updated.push(response.data);
      } else {
        throw createErrorFromApiResponse(response);
      }
    }

    'meta' in action && action.meta.promise.resolve(updated);
  } catch (e) {
    yield put(NotifyError(`Error while updating a user: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject(e as Error);
  }

  for (const user of updated) {
    yield put(umUsersActions.updateUserSuccess({ user, sub: user.sub }));
  }
  yield clearQueryCache('userManagement');
}

function* updateUserWithReLogin(action: umUsersActions.UpdateWithReLogin) {
  const response: ApiResponse = yield call(umClient.updateUser, action.payload);
  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';

    yield put(NotifyError(`Error while updating a user: ${ message }`));

    return;
  }

  const user: User = response.data;

  yield put(umUsersActions.updateUserSuccess({ user, sub: action.payload.sub }));
  yield clearQueryCache('userManagement');
  yield Auth.signOut();
}

function* fetchUsersSaga(action: umUsersActions.FetchUsers) {
  const response: ApiResponseWithTotal = yield call(umClient.fetchUsers, action.params);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';
    yield put(umUsersActions.fetchUsersFailure(message));
    yield put(NotifyError(`Error while fetching users: ${ message }`));
    return;
  }

  const users: User[] = response.data;
  const total: number = response.total;

  yield put(umUsersActions.fetchUsersSuccess({ users, params: action.params, total }));
}

type ChangeUserPassword = umUsersActions.ChangePasswordByUser;
type ChangeUserPasswordAction = (
  | ChangeUserPassword
  | ActionWithPromise<ChangeUserPassword, void>
);

function* changeUserPasswordSaga(action: ChangeUserPasswordAction) {
  const response: ApiResponse = yield call(umClient.changePasswordByUser, action.newPassword, action.oldPassword);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';

    yield put(NotifyError(`Error while changing user password: ${ message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(message));
    }

    return;
  }

  yield put(NotifySuccess(`Your password has been changed successfully`));

  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

type ChangePasswordForUser = umUsersActions.ChangePasswordForAnotherUser;
type ChangePasswordForUserAction = (
  | ChangePasswordForUser
  | ActionWithPromise<ChangePasswordForUser, void>
  );

function* changePasswordForAnotherUserSaga(action: ChangePasswordForUserAction) {
  const response: ApiResponse = yield call(umClient.changePasswordForAnotherUser, action.userSub, action.newPassword, action.permanent);

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';

    yield put(NotifyError(`Error while changing user password: ${ message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(message));
    }

    return;
  }

  yield put(NotifySuccess(`User password has been changed successfully`));

  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

export const userManagementSagas = [
  takeEvery(umUsersActions.DELETE_USER, deleteUserSaga),
  takeEvery(umPermissionsActions.CREATE_PERMISSIONS, createPermissionsSaga),
  takeEvery(umPermissionsActions.DELETE_PERMISSIONS, deletePermissionsSaga),
  takeEvery(umPermissionsActions.UPDATE_PERMISSIONS, updatePermissionsSaga),
  takeEvery(umPermissionsActions.FETCH_PERMISSIONS, fetchPermissionsSaga),
  takeEvery(umUsersActions.FETCH_USER, fetchUserSaga),
  takeEvery(umUsersActions.UPDATE_USER, updateUserSaga),
  takeLeading(umUsersActions.USER_ADD_TO_PROJECT, doAddUsersToProject),
  takeEvery(umUsersActions.FETCH_USERS, fetchUsersSaga),
  takeEvery(umUsersActions.UPDATE_WITH_RE_LOGIN, updateUserWithReLogin),
  takeEvery(umUsersActions.CHANGE_PASSWORD_FOR_CURRENT_USER, changeUserPasswordSaga),
  takeEvery(umUsersActions.CHANGE_PASSWORD_FOR_ANOTHER_USER, changePasswordForAnotherUserSaga),
];
