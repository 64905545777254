import { ChangeEvent } from 'react';
import { useAuthUserSelector } from 'hooks';
import { useHistory } from 'react-router';
import { useInvite, CHECK_STATUS } from './hooks/useInvite';
import { useInviteForm } from './hooks/useInviteForm';

// components
import { CheckboxField, PasswordField, TextField, Button } from '@aws-amplify/ui-react';
import { PageLoader } from 'components/Loaders';
import { FieldBlock } from './FieldBlock';

// styles
import { useStyles } from './styles';

export const InviteForm = (): JSX.Element => {
  const classes = useStyles();
  const { signedIn } = useAuthUserSelector();
  const history = useHistory();
  const { handleSubmit, checkStatus, isSingUpFetching } = useInvite();
  const {
    handleChange,
    validateField,
    validatePasswordField,
    validateRepeatPasswordField,
    handleValidate,
    state,
    errors,
  } = useInviteForm();

  if (signedIn) {
    return (
      <div className={ classes.wrap }>
        <h1 className={ classes.invalidText }>You have signed up already</h1>
        <Button
          marginTop="10px"
          className={ classes.btn }
          variation="primary"
          onClick={ () => history.push('/') }
          ariaLabel="Go to dashboard"
        >Go to dashboard</Button>
      </div>
    );
  }

  if (checkStatus === CHECK_STATUS.FETCHING || isSingUpFetching) {
    return <PageLoader/>;
  }

  if (checkStatus === CHECK_STATUS.INVALID) {
    return (
      <div className={ classes.wrap }>
        <h1 className={ classes.invalidText }>Invite link is invalid.</h1>
      </div>
    );
  }

  return (
    <div className={ classes.wrap }>
      <FieldBlock>
        <TextField
          required
          label=""
          labelHidden
          placeholder="First Name *"
          value={ state.firstName }
          onChange={ (e: ChangeEvent<HTMLInputElement>) => handleChange('firstName', e.target.value) }
          onBlur={ () => validateField('firstName') }
          hasError={ Boolean(errors.firstName) }
          errorMessage={ errors.firstName }
        />
      </FieldBlock>
      <FieldBlock>
        <TextField
          required
          label=""
          labelHidden
          placeholder="Last Name *"
          value={ state.lastName }
          onChange={ (e: ChangeEvent<HTMLInputElement>) => handleChange('lastName', e.target.value) }
          onBlur={ () => validateField('lastName') }
          hasError={ Boolean(errors.lastName) }
          errorMessage={ errors.lastName }
        />
      </FieldBlock>
      <FieldBlock>
        <PasswordField
          required
          label=""
          labelHidden
          autoComplete="new-password"
          placeholder="Password *"
          value={ state.password }
          onChange={ (e: ChangeEvent<HTMLInputElement>) => {
            validatePasswordField(e.target.value);
            handleChange('password', e.target.value);
          } }
          onBlur={ () => validateField('password') }
          hasError={ Boolean(errors.password) }
          errorMessage={ errors.password }
        />
      </FieldBlock>
      <FieldBlock>
        <PasswordField
          required
          label=""
          labelHidden
          autoComplete="new-password"
          placeholder="Confirm password *"
          value={ state.repeatPassword }
          onChange={ (e: ChangeEvent<HTMLInputElement>) => {
            // @TODO define a standart approach in 'project level' for forms and add here
            if (errors.repeatPassword) {
              validateRepeatPasswordField(e.target.value);
            }
            handleChange('repeatPassword', e.target.value);
          } }
          onBlur={ () => validateRepeatPasswordField(state.repeatPassword) }
          hasError={ Boolean(errors.repeatPassword) }
          errorMessage={ errors.repeatPassword }
        />
      </FieldBlock>
      <FieldBlock>
        <CheckboxField
          name=""
          value=""
          label="Minimum length 8 characters"
          checked={ !errors.min }
        />
        <CheckboxField
          name=""
          value=""
          label="Contains number"
          checked={ !errors.number }
        />
        <CheckboxField
          name=""
          value=""
          label="Contains special character"
          checked={ !errors.specialChar }
        />
        <CheckboxField
          name=""
          value=""
          label="Contains uppercase letter"
          checked={ !errors.uppercase }
        />
        <CheckboxField
          name=""
          value=""
          label="Contains lowercase letter"
          checked={ !errors.lowercase }
        />
      </FieldBlock>
      <Button
        className={ classes.btn }
        variation="primary"
        onClick={ async () => {
          if (handleValidate()) {
            await handleSubmit({
              newPassword: state.password,
              firstName: state.firstName,
              lastName: state.lastName,
            });
          }
        } }
        ariaLabel=""
      >
        Sign Up
      </Button>
    </div>
  );
};