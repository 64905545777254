import { useForm } from 'react-hook-form-v6';
import { useFormActionLoader } from 'hooks';
import { useUserCreateMutation } from 'hooks/user-managment';
import { UserCreateParams, UserRoleEnum } from 'models/user-management';
// components
import { Button } from '@mui/material';
import { CommonDialog } from 'components/Dialogs';
import { UserInviteForm } from './UserInviteForm';

interface Props {
  user?: Partial<UserCreateParams>;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const UserInviteDialog = ({ user, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const { createUser } = useUserCreateMutation();

  const defaultValues: UserCreateParams = {
    owner: 0,
    role: UserRoleEnum.User,
    email: '',
    permission: '',
    projects: [],
    ...user
  };

  const form = useForm<UserCreateParams>({
    defaultValues: defaultValues,
  });

  const handleInvite = async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      return false;
    }

    doAction({
      action: createUser({ ...defaultValues, ...form.getValues() }),
      onSuccess: onClose,
    });
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title="Invite user"
      content={ <UserInviteForm form={ form }/> }
      actions={
        <>
          <Button color="primary" onClick={ onClose }>Cancel</Button>
          <Button color="primary" variant="contained" onClick={ handleInvite }>Invite</Button>
        </>
      }
    />
  );
};
