import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RootState } from 'reducers';

import { ApiResponse, ReasonEnum } from 'models';
import { Dictionary } from 'models/dictionary';
import { Endpoint } from 'models/rabbit';

import { getEndpoints, getMessageTypes } from 'clients/rabbit';
import { fetchHardwareTypes } from 'clients/device-management';

import * as RabbitAction from 'actions/rabbit';
import * as DictAction from 'actions/dictionary';
import { NotifyError } from 'actions/notifier';
import { LoadTemplateSuiteDataByParams } from 'actions/caller';

function* loadEndpoints() {
  const response: ApiResponse = yield call(getEndpoints, {});
  if (response.reason === ReasonEnum.Ok) {
    yield put(DictAction.DictEndpointsChange(response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching dictionary endpoints: ${ message }`));
  }
}

function* loadHardwareTypes() {
  const response: ApiResponse = yield call(fetchHardwareTypes);
  if (response.reason === ReasonEnum.Ok) {
    yield put(DictAction.DictHardwareTypesChange(response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching dictionary hardware types: ${ message }`));
  }
}

function* loadMessageTypes() {
  const response: ApiResponse = yield call(getMessageTypes);
  if (response.reason === ReasonEnum.Ok) {
    yield put(DictAction.DictMessageTypesChange(response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching dictionary message types: ${ message }`));
  }
}

function* loadDictionary(action: DictAction.DictionaryLoad) {
  const state: RootState = yield select();
  if (state.dictionary.inited.has(action.dictionary)) {
    return;
  }

  state.dictionary.inited.add(action.dictionary);
  switch (action.dictionary) {
    case Dictionary.CALLER_TEMPLATE_SUITES:
      yield put(LoadTemplateSuiteDataByParams({}));
      break;

    case Dictionary.DM_HARDWARE_TYPES:
      yield loadHardwareTypes();
      break;

    case Dictionary.RABBIT_ENDPOINTS:
      yield loadEndpoints();
      break;

    case Dictionary.RABBIT_MESSAGE_TYPES:
      yield loadMessageTypes();
      break;
  }
}

function* changeEndpoint(action: RabbitAction.ChangeEndpoint) {
  const state: RootState = yield select();
  if (!state.dictionary.inited.has(Dictionary.RABBIT_ENDPOINTS)) {
    return;
  }

  let endpoints = state.dictionary.endpoints;
  if (action.options.create && endpoints.indexOf(action.endpoint) === -1) {
    endpoints.push(action.endpoint);
  } else if (action.options.delete) {
    endpoints = endpoints.filter((endpoint: Endpoint) => (endpoint.id !== action.endpoint.id));
  } else if (action.options.update) {
    endpoints = endpoints.map((endpoint: Endpoint) => (endpoint.id === action.endpoint.id ? action.endpoint : endpoint));
  }

  yield put(DictAction.DictEndpointsChange(endpoints));
}

export default [
  takeEvery(DictAction.DictionaryLoad, loadDictionary),
  takeEvery(RabbitAction.RABBIT_ENDPOINT_CHANGE, changeEndpoint),
];
