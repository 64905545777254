import { useQuery } from 'react-query';
import { useFormActionNotifier } from 'hooks';
import { fetchUsers, reinvite } from 'clients/user-management';
import { GetUsersParams, User } from 'models/user-management';
import { ensureRequestSucceeded } from 'utils/clients';

export const useUserReinvite = () => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const handleReinvite = async (userSub: string) => {
    try {
      await reinvite(userSub);
      notifySuccess('Invite sended succesfully');
    } catch (e) {
      notifyError('Send invite failed');
    }
  };

  return {
    handleReinvite
  };
};


export interface UsersState {
  isLoading: boolean;
  users: User[];
  total: number;
}

export function useUsersSelector(params: GetUsersParams, enabled = true): UsersState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['userManagement/user', params],
    queryFn: async () => {
      const result = await fetchUsers(params);
      ensureRequestSucceeded(result);

      return {
        users: result.data ?? [],
        total: result.total ?? 0,
      };
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    onError: e => notifyError(`Error while fetching users: ${ (e as Error).message }`),
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    users: queryResult.data?.users ?? [],
    total: queryResult.data?.total ?? 0,
  };
}

export function useUsersDictionary(enabled = true): UsersState {
  return useUsersSelector({ limit: 999, offset: 0 }, enabled);
}
