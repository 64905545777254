import { useAuthUserSelector, useDialog } from 'hooks';
import { LorawanStation } from 'models/base-station';
import { canEditLorawanStation, canRemoveLorawanStation, isAdmin } from 'utils/permissions';

// components
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { OwnerName } from 'components/Labels';
import { OnlineStatus, LorawanMessagesButton } from '../../../../widgets';
import { EditLorawanDialog } from './widgets/EditLorawanDialog';

// styles
import { useStyles } from '../styles';
import { DeleteLorawanDialog } from './widgets/DeleteDialog';

interface Props {
  station: LorawanStation;
}

export const LorawanStationInfo = (props: Props): JSX.Element => {
  const { station } = props;
  const classes = useStyles();

  const user = useAuthUserSelector();
  const editDialog = useDialog();
  const deleteDialog = useDialog();

  return (
    <Paper className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={8}>
          <Box className={classes.infoContainer}>
            <Box className={classes.infoBlock}>
              <Typography className={classes.header}>Gateway EUI </Typography>
              <Typography className={classes.value}>{station.eui}</Typography>
            </Box>
            <Box className={classes.infoBlock}>
              <Typography className={classes.header}>RF Region</Typography>
              <Typography className={classes.value}>
                {station.rf_region}
              </Typography>
            </Box>
            {isAdmin(user) && (
              <Box className={classes.infoBlock}>
                <Typography className={classes.header}>Owner</Typography>
                <Typography className={classes.value}>
                  <OwnerName ownerId={station.owner_id}/>
                </Typography>
              </Box>
            )}
            <Box className={classes.infoBlock}>
              <Typography className={classes.header}>Online status</Typography>
              <Typography className={classes.value}>
                {<OnlineStatus stationStatus={station.status} variant="text"/>}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" justifyContent="flex-end" height="100%">
            <Box className={classes.actionBlock}>
              <LorawanMessagesButton station={station}/>
              {canEditLorawanStation(user) && (
                <Button
                  variant="text"
                  className={classes.buttonEdit}
                  onClick={editDialog.open}
                >EDIT&nbsp;STATION</Button>
              )}
              {canRemoveLorawanStation(user) && (
                <Button
                  variant="text"
                  className={classes.buttonDelete}
                  onClick={deleteDialog.open}
                >REMOVE&nbsp;STATION</Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {editDialog.isMounted && (
        <EditLorawanDialog
          station={station}
          isOpen={editDialog.isOpen}
          onClose={editDialog.close}
          onCloseEnd={editDialog.unmount}
        />
      )}
      { deleteDialog.isMounted && (
        <DeleteLorawanDialog
          station={ station }
          isOpen={ deleteDialog.isOpen }
          onClose={ deleteDialog.close }
          onCloseEnd={ deleteDialog.unmount }
        />
      )}
    </Paper>
  );
};
