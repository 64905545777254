import { stubTrue } from 'lodash';
import { User, UserRoleEnum } from 'models/user-management';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Value = User['role'];
type Props =
  Partial<Omit<CommonProps<Value>, 'options'>>
  & (SingleProps<Value> | MultiProps<Value>)
  & { filter?: (role: Value) => boolean }

export const UserRoleSelectControl = (props: Props): JSX.Element => {
  const filterCallback = props.filter ?? stubTrue;
  const options: Option<Value>[] =
    Object.values(UserRoleEnum)
      .filter(filterCallback)
      .map(role => ({
        value: role,
        label: role.charAt(0).toUpperCase() + role.slice(1)
      }));

  return (
    <SelectControl
      name="userRole"
      label="Role"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      checkSelected={ true }
      isDisabled={ props.isDisabled || !options.length }
    />
  );
};