import { MUIDataTableOptions } from 'mui-datatables';
import { useState } from 'react';

export const DEFAULT_LIMIT = 20;
export const DEFAULT_LIMIT_OPTIONS = [20, 50, 100];
export const DEFAULT_OFFSET = 0;

export type Pagination = {
  offset: number;
  limit: number;
  page: number;
  rowCount: number;
  onChangePage: (page: number) => void;
  onChangeRowCount: (rowCount: number) => void;
}

export type Paginator<TItem> =
  Pagination
  & {
    data: TItem[];
    total: number;
  }

export const getDatatableOptions = <Item>(paginator: Paginator<Item>): MUIDataTableOptions => ({
  serverSide: true,
  pagination: true,
  count: Number(paginator.total),
  page: paginator.page,
  rowsPerPage: paginator.rowCount,
  onChangePage: paginator.onChangePage,
  onChangeRowsPerPage: paginator.onChangeRowCount,
});


/**
 * Emulate server side pagination by static array for MUIDataTable
 * When switching to a different rows per page, the current topmost result element must also be present in the new output.
 */
export const useInternalPaginator = <TItem>(data: TItem[], limit = DEFAULT_LIMIT, options = DEFAULT_LIMIT_OPTIONS): MUIDataTableOptions & { data: TItem[] } => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(limit);

  return {
    data: data.slice(page * rowsPerPage, (page + 1) * rowsPerPage),

    serverSide: true,
    pagination: true,
    count: data.length,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: options,
    onChangePage: currentPage => setPage(currentPage),
    onChangeRowsPerPage: newValue => {
      setRowsPerPage(newValue);
      setPage(Math.floor(((page * rowsPerPage) + 1) / newValue));
    },
  };
};