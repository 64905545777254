import {
  createFieldFromRegularInputWithError,
  createFieldFromSelectorWithError,
} from 'components/Form/Field/field-creators';

// components
import { OwnersSingleSelectControl, ProjectSelectControl } from 'components/Controls';
import SelectPermissionsTemplateInput from 'pages/UserManagement/User/widgets/SelectPermissionsTemplate';

export const SelectOwner = createFieldFromSelectorWithError(OwnersSingleSelectControl);

export const SelectPermissionsTemplate = createFieldFromRegularInputWithError(
  SelectPermissionsTemplateInput,
);

export const SelectProject = createFieldFromSelectorWithError(ProjectSelectControl);
