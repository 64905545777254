import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { useFormActionLoader, useLorawanStationsById } from 'hooks';
import { parseUrlParams } from 'utils/routing/query';
import { DownloadCertificatesLorawan } from 'actions/base-station';
// components
import { Grid } from '@mui/material';
import { PageLoader } from 'components/Loaders';
import { LorawanStationInfo } from './widgets/StationInfo/LorawansStationInfo';
import { BaseStationManagement } from './widgets/StationManagement/BaseStationManagement';
import { BaseStationNotFound } from './BaseStationNotFound';

// styles
import { useStyles } from './styles';


interface Props {
  stationId: number;
}

interface QueryParams {
  downloadCertificates?: string;
}

const HelmetComponent = ({ stationId }: { stationId: number }) => (
  <Helmet><title>{`Base Station #${stationId}`}</title></Helmet>
);

export const LorawanStationDetailPage = (props: Props): JSX.Element => {
  const { stationId } = props;
  const { search } = useLocation();
  const classes = useStyles();
  const { station, isLoading } = useLorawanStationsById(stationId);
  const { doAction } = useFormActionLoader();
  const [downloadTriggered, setDownloadTriggered] = useState(false);

  const queryParams = parseUrlParams<QueryParams>(search, params => params);
  const { downloadCertificates } = queryParams;


  useEffect(() => {
    if (
      !downloadTriggered &&
      downloadCertificates === '1' &&
      !isLoading &&
      station
    ) {
      setDownloadTriggered(true);
      doAction({
        action: DownloadCertificatesLorawan(station),
      });
    }
  }, [isLoading, station, downloadCertificates, downloadTriggered, doAction]);

  if (isLoading) {
    return (
      <>
        <HelmetComponent stationId={stationId}/>
        {isLoading ? <PageLoader/> : <BaseStationNotFound stationId={stationId}/>}
      </>
    );
  }

  return (
    <>
      <HelmetComponent stationId={stationId}/>
      <Grid
        className={classes.root}
        container
        spacing={2}
        data-testid="base-station-page"
        data-station-id={stationId}
      >
        <Grid item xs={12} sm={12} md={12}>
          <LorawanStationInfo station={station}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <BaseStationManagement station={station}/>
        </Grid>
      </Grid>
    </>
  );
};