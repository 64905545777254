import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form-v6';
import { updateUser, updateWithRelogin } from 'actions/user-management/users';
import { User, UserUpdateParams } from 'models/user-management';
import { RootState } from 'reducers';
import { OwnersSingleSelectControl } from 'components/Controls';
import SelectUserRoleInput from 'pages/UserManagement/User/widgets/SelectUserRole';
import { dispatchAsync } from 'utils/store';

// components
import { Button as MuiButton, Box, Paper, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { Button } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';
import { SuccessButton } from 'components/Buttons';

// styles
import { ThemeProvider } from 'styles/utils';
import { successTheme } from 'styles/themes';

type SelectedParams = 'first_name' | 'last_name' | 'user_group';

type AccountFormValues = Pick<UserUpdateParams['props'], SelectedParams> & {
  role: UserUpdateParams['props']['role'] | null;
};

interface AccountFormProps {
  user: User;
  onEditComplete: () => void;
}

const AccountForm: React.FC<AccountFormProps> = (props) => {
  const { user } = props;

  const { data: stateUser, isAdmin } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  const [isMyProfileChange, changeMyProfile] = useState<boolean>(false);
  const [newUserProps, setNewUserProps] = useState<UserUpdateParams['props']>(user);

  const handleSubmit = async (values: AccountFormValues) => {
    // Error: "Only admins can set role", if nonAdmin user tries to change profile values https://nwaveio.atlassian.net/browse/BNIV-1941
    const role = isAdmin && values.role ? values.role : undefined;
    const userUpdateProps: UserUpdateParams['props'] = {
      ...user,
      ...values,
      role,
    };
    if(Number(stateUser.user_id) === user.id) {
      changeMyProfile(true);
    }
    setNewUserProps(userUpdateProps);

    return dispatchAsync(dispatch, updateUser({ sub: user.sub, props: userUpdateProps }));
  };

  const handleSubmitWithLogout = () => dispatch(updateWithRelogin({ sub: user.sub, props: newUserProps }));

  const form = useForm({
    defaultValues: user
  });

  return (
    <>
      <Dialog
        open={ isMyProfileChange }
        onClose={ () => changeMyProfile(false) }
        fullWidth
      >
        <DialogContent>
          <Typography>You need to relogin for the changes to be applied</Typography>
        </DialogContent>
        <DialogActions>
          <SuccessButton label="Relogin now" onClick={ () => {
            handleSubmitWithLogout();
            changeMyProfile(false);
            props.onEditComplete();
          }}/>
          <MuiButton color="inherit" onClick={() => changeMyProfile(false)}>
            Skip
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Paper style={{ padding: '8px' }}>
        <BlockToolbar>
          <BlockTitle>
                Edit account
          </BlockTitle>

          <MuiButton
            color="inherit"
            disabled={form.formState.isSubmitting}
            onClick={props.onEditComplete}
          >
                Cancel
          </MuiButton>

          <ThemeProvider theme={successTheme}>
            <Button
              color="primary"
              disabled={!form.formState.isDirty}
              pending={form.formState.isSubmitting}
              onClick={async () => {
                const v = form.getValues();
                await handleSubmit(v);
              } }
            >
                Save
            </Button>
          </ThemeProvider>
        </BlockToolbar>

        <Box pb={1}>
          <Controller
            render={props => <TextField
              {...props}
              fullWidth
              label="First name"
            />}
            name="first_name"
            control={form.control}
          />
        </Box>
        <Box pb={1}>
          <Controller
            render={props => <TextField
              {...props}
              fullWidth
              label="Last name"
            />}
            name="last_name"
            control={form.control}
          />
        </Box>
        {isAdmin && <Controller
          render={props => <SelectUserRoleInput {...props} label="Role" />}
          name="role"
          control={form.control}
          rules={{ required: true }}
        />}
        {isAdmin && <Controller
          render={props =>
            <OwnersSingleSelectControl
              {...props}
              selected={props.value}
              isClearable={false}
              changeHandler={props.onChange}
            />
          }
          name="user_group"
          control={form.control}
          rules={{ required: true }}
        />}
      </Paper>
    </>
  );
};

export default AccountForm;
