import { createStyles, makeStyles, Theme } from 'styles/utils';
import { margins } from 'styles/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...margins(theme)
    },
    value: {
      marginBottom: theme.spacing(1.5),
      wordBreak: 'break-all',
    },
  }),
);

export default useStyles;
