import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponse, ReasonEnum } from 'models';
import * as Action from 'actions/base-station';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { createStationLorawan, deleteLoraStation, downloadCertificatesLora } from 'clients/base-stations';
import { ActionWithPromise } from 'utils/store';
import { LorawanStation } from 'models/base-station';
import { createErrorFromApiResponse } from 'utils/errors';
import { clearQueryCache } from 'utils/react-query';

type CreateAction =
  | Action.CreateStationLorawan
  | ActionWithPromise<Action.CreateStationLorawan, LorawanStation>

function* doCreateStationLora(action: CreateAction) {
  const { params } = action;

  const response: ApiResponse = yield call(createStationLorawan, params);

  if (response.reason === ReasonEnum.Ok) {
    const newStation: LorawanStation = response.data as LorawanStation;
    yield put(NotifySuccess(`LoRaWAN station ${newStation.eui} has been created`));
    'meta' in action && action.meta.promise.resolve(newStation);
    yield clearQueryCache(['lorawanBaseStation/stations']);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while creating LoRaWAN base station: ${error.message}`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type DeleteAction =
  | Action.DeleteStationLorawan
  | ActionWithPromise<Action.DeleteStationLorawan, LorawanStation>

function* doDeleteStationLora(action: DeleteAction) {
  const { station } = action;
  const response: ApiResponse = yield call(deleteLoraStation, station.id);

  if (response.reason === ReasonEnum.Ok) {
    yield put(NotifySuccess(`LoRaWAN station #${station.id} has been deleted`));
    'meta' in action && action.meta.promise.resolve(station);
    yield clearQueryCache(['lorawanBaseStation/stations']);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while deleting LoRaWAN base station #${station.id}: ${error.message}`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type DownloadCertificatesAction =
  | Action.DownloadCertificatesLorawan
  | ActionWithPromise<Action.DownloadCertificatesLorawan, LorawanStation>

function* doDownloadCertificatesStationLora(action: DownloadCertificatesAction) {
  const { station } = action;
  const response: Blob = yield call(downloadCertificatesLora, station.id);

  try {
    const downloadUrl = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${station.eui}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    yield put(NotifySuccess(`Certificates for LoRaWAN station #${station.id} has been downloaded`));
    'meta' in action && action.meta.promise.resolve(station);
  } catch (error) {
    yield put(NotifyError(`Error while downloading certificates for LoRaWAN base station #${station.id}`));
    'meta' in action && action.meta.promise.reject(error as Error);
  }
}

export default [
  takeEvery(Action.LORAWAN_BASE_STATION_LOCATION_CREATE, doCreateStationLora),
  takeEvery(Action.LORAWAN_BASE_STATION_DELETE, doDeleteStationLora),
  takeEvery(Action.LORAWAN_BASE_STATION_DOWNLOAD_CERTIFICATES, doDownloadCertificatesStationLora),
];