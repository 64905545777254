import { ALL_PROJECTS, User } from 'models/user-management';
import { useProjectsDictionarySelector } from 'hooks/user-managment';
// components
import { Box, Chip, Typography } from '@mui/material';
import { InlineLoader } from 'components/Loaders';

interface Props {
  user: User;
}

export const UserProjects = ({ user }: Props): JSX.Element => {
  const { isLoading, projects } = useProjectsDictionarySelector();
  if (user.projects === ALL_PROJECTS) {
    return (
      <Typography>All</Typography>
    );
  }

  if (!user.projects.length) {
    return (
      <Typography>-</Typography>
    );
  }

  if (isLoading) {
    return (
      <InlineLoader/>
    );
  }

  return (
    <Box
      sx={ theme => ({
        margin: `${ theme.spacing(-0.4) }px 0`,
        '& > *': {
          margin: theme.spacing(0.5),
        },
      }) }
    >
      { user.projects.map(projectId => {
        const project = projects.find(p => p.id === projectId);
        return (
          <Chip
            key={ projectId }
            label={ project ? project.name : `#${ projectId }` }
            variant={ project ? 'filled' : 'outlined' }
          />
        );
      }) }
    </Box>
  );
};