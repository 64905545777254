import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      fontWeight: 900,
      fontSize: '0.87rem'
    },
    value: {
      marginBottom: theme.spacing(1.5),
      wordBreak: 'break-all',
    },
    breakAll: {
      wordBreak: 'break-all',
    },
  }),
);

export default useStyles;
