import { User } from 'models/user-management';
import { usePermissionSelector } from 'hooks/user-managment';
// components
import { Typography } from '@mui/material';
import { InlineLoader } from 'components/Loaders';

interface Props {
  user: User;
}

export const UserPermissions = ({ user }: Props): JSX.Element => {
  const { isLoading, permission } = usePermissionSelector(user.permissions);
  if (!user.permissions) {
    return (
      <Typography>-</Typography>
    );
  }

  if (isLoading) {
    return (
      <InlineLoader/>
    );
  }

  if (!permission) {
    return (
      <Typography>{ user.permissions }</Typography>
    );
  }

  return (
    <Typography>{ permission.template ? permission.name : 'Individual' }</Typography>
  );
};