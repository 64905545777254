import { Controller } from 'react-hook-form-v6';
import { UseFormMethods } from 'react-hook-form-v6/dist/types/form';
import { UserCreateParams, UserRoleEnum, NWAVE_GROUP, PROJECT_ALL } from 'models/user-management';
import { validateEmail } from 'utils/validator';
import { useAuthUserSelector } from 'hooks';
import { FieldError } from 'react-hook-form-v6/dist/types/errors';
// components
import { FormGroup, TextField } from '@mui/material';
import { OwnersSingleSelectControl, ProjectSelectControl, UserPermissionSelectControl, UserRoleSelectControl } from 'components/Controls';

interface Props {
  form: UseFormMethods<UserCreateParams>;
}

export const UserInviteForm = ({ form }: Props): JSX.Element => {
  const { isAdmin } = useAuthUserSelector();
  const owner = form.watch('owner');

  return (
    <FormGroup>
      { isAdmin && (
        <Controller
          control={ form.control }
          rules={ { required: 'Required', } }
          name="owner"
          render={ props =>
            <OwnersSingleSelectControl
              { ...props }
              isClearable={ false }
              selected={ props.value }
              changeHandler={ async v => {
                props.onChange(v);
                if (v !== NWAVE_GROUP) {
                  form.setValue('role', UserRoleEnum.User);
                }
                form.setValue('permission', '');
                form.setValue('projects', []);
              } }
              error={ form.errors.owner?.message }
            />
          }
        />
      ) }
      <Controller
        control={ form.control }
        name="email"
        rules={ {
          required: 'Required',
          validate: email => validateEmail(email) ? undefined : 'Invalid e-mail',
        } }
        render={ props =>
          <TextField
            { ...props }
            label="E-mail"
            error={ !!form.errors.email }
            helperText={ form.errors.email?.message }
          />
        }
      />
      { isAdmin && (
        <Controller
          control={ form.control }
          rules={ { required: 'Required' } }
          name="role"
          render={ props =>
            <UserRoleSelectControl
              { ...props }
              selected={ props.value }
              error={ form.errors.role?.message }
              filter={ role => {
                if (role === UserRoleEnum.Admin) {
                  return owner === NWAVE_GROUP;
                }
                return true;
              } }
            />
          }
        />
      ) }
      <Controller
        control={ form.control }
        rules={ { required: 'Required' } }
        name="permission"
        render={ props =>
          <UserPermissionSelectControl
            { ...props }
            selected={ props.value }
            error={ form.errors.permission?.message }
            filter={ el => owner === el.owner_id }
          />
        }
      />
      <Controller
        control={ form.control }
        rules={ { required: 'Required' } }
        name="projects"
        render={ props =>
          <ProjectSelectControl
            { ...props }
            isMulti
            isMultiLine
            withAllOption
            selected={ props.value }
            onChange={ v => {
              const before = form.getValues('projects');
              if (before.includes(PROJECT_ALL) && v.length > 1) {
                v = v.filter(el => el !== PROJECT_ALL);
              } else if (v.includes(PROJECT_ALL)) {
                v = [PROJECT_ALL];
              }
              props.onChange(v);
            } }
            error={ (form.errors.projects as unknown as FieldError)?.message }
            filter={ el => owner === el.owner_id }
          />
        }
      />
    </FormGroup>
  );
};
