import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useLocation, useHistory } from 'react-router-dom';
import { useFormActionNotifier } from 'hooks';
import { Auth } from 'aws-amplify';
import { fetchUser, updateUser } from 'clients/user-management';
import { clearQueryCache } from 'utils/react-query';

export enum CHECK_STATUS {
  FETCHING,
  VALID,
  INVALID
}

type TConfirmUserParams = { newPassword: string, firstName: string, lastName: string }


const getInviteToken = (url?: string) => {
  const token = url?.split('/invite/').filter(el => el);
  return token && token.length > 0 ? token[0] : '';
};

const getCredentials = (credentialString: string) => {
  const creds = JSON.parse(credentialString);
  return {
    email: creds?.email,
    password: creds?.token
  };
};

export const useInvite = () => {
  const { notifyError } = useFormActionNotifier();
  const history = useHistory();
  const location = useLocation();
  const token = getInviteToken(location.pathname);
  const [isSingUpFetching, setIsSingUpFetching] = useState(false);
  const [checkStatus, setCheckStatus] = useState(CHECK_STATUS.FETCHING);

  // check - link is valid
  const handleCheckIsValidLink = async () => {
    if (token) {
      try {
        const { email, password } = getCredentials(atob(token));
        await Auth.signIn(email, password);
        setCheckStatus(CHECK_STATUS.VALID);
      } catch {
        setCheckStatus(CHECK_STATUS.INVALID);
      }
    }
  };

  useEffectOnce(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleCheckIsValidLink();
  });

  const handleSubmit = async ({
    newPassword,
    firstName,
    lastName
  }: TConfirmUserParams) => {
    setIsSingUpFetching(true);
    if (token) {
      try {
        const { email, password } = getCredentials(atob(token));
        const currentAwsUser = await Auth.signIn(email, password);
        await Auth.completeNewPassword(currentAwsUser, newPassword);
        const nwaveUser = await fetchUser(currentAwsUser.username);
        const user = nwaveUser.data;
        if (user) {
          await updateUser({
            sub: user.sub,
            props: {
              first_name: firstName,
              last_name: lastName,
              permissions: user.permissions,
              timezone: user.timezone,
              phone_number: user.phone_number,
              user_group: user.user_group,
              role: user.role,
              projects: user.projects,
            }
          });
        }
        void clearQueryCache('userManagement');
        history.push('/');
      } catch (error) {
        notifyError(`Sing up error: ${ (error as { message: string }).message }`);
      } finally {
        setIsSingUpFetching(false);
      }
    }
  };

  return {
    handleSubmit,
    isSingUpFetching,
    checkStatus,
  };
};
