import { LorawanStation, StationLocation, StationLoRaWANCreate } from 'models/base-station';

export const LORAWAN_BASE_STATION_LOCATION_CREATE = 'LORAWAN_BASE_STATION_LOCATION_CREATE';
export const LORAWAN_BASE_STATION_LOCATION_ATTACH = 'LORAWAN_BASE_STATION_LOCATION_ATTACH';
export const LORAWAN_BASE_STATION_LOCATION_DETACH = 'LORAWAN_BASE_STATION_LOCATION_DETACH';
export const LORAWAN_BASE_STATION_DELETE = 'LORAWAN_BASE_STATION_DELETE';
export const LORAWAN_BASE_STATION_DOWNLOAD_CERTIFICATES = 'LORAWAN_BASE_STATION_DOWNLOAD_CERTIFICATES';
export interface AttachLoraStationLocation {
  readonly type: typeof LORAWAN_BASE_STATION_LOCATION_ATTACH;
  station: LorawanStation;
  location: StationLocation;
}

export interface CreateStationLorawan {
  readonly type: typeof LORAWAN_BASE_STATION_LOCATION_CREATE;
  readonly params: StationLoRaWANCreate;
}

export interface DetachLorawanStationLocation {
  readonly type: typeof LORAWAN_BASE_STATION_LOCATION_DETACH;
  station: LorawanStation;
}

export interface DeleteStationLorawan {
  readonly type: typeof LORAWAN_BASE_STATION_DELETE;
  station: LorawanStation;
}

export interface DownloadCertificatesLorawan {
  readonly type: typeof LORAWAN_BASE_STATION_DOWNLOAD_CERTIFICATES;
  station: LorawanStation;
}

export const AttachLoraStationLocation = (station: LorawanStation, location: StationLocation): AttachLoraStationLocation => ({
  type: LORAWAN_BASE_STATION_LOCATION_ATTACH, station, location
});

export const CreateStationLorawan = (params: StationLoRaWANCreate): CreateStationLorawan => ({
  type: LORAWAN_BASE_STATION_LOCATION_CREATE, params
});

export const DeleteStationLorawan: (station: LorawanStation) => DeleteStationLorawan = (station: LorawanStation) => ({
  type: LORAWAN_BASE_STATION_DELETE, station
});

export const DownloadCertificatesLorawan: (station: LorawanStation) => DownloadCertificatesLorawan = (station: LorawanStation) => ({
  type: LORAWAN_BASE_STATION_DOWNLOAD_CERTIFICATES, station
});

export const DetachLorawanStationLocation = (station: LorawanStation): DetachLorawanStationLocation => ({
  type: LORAWAN_BASE_STATION_LOCATION_DETACH, station
});

export type Action =
  | AttachLoraStationLocation
  | CreateStationLorawan
  | DetachLorawanStationLocation