
export const NWAVE_GROUP = 1

export interface UserGroup {
  id: number;
  name: string;
  address?: string;
  phone_number?: string;
  client_access_code?: number;
}

export interface GetUserGroupParams {
  limit?: number;
  offset?: number;
}

export interface UserGroupUpdate {
  name: string;
  address?: string;
  phone_number?: string;
  client_access_code?: number;
}

export interface UserGroupCreate {
  name: string;
  address?: string;
  phone_number?: string;
  client_access_code?: number;
}
